import { render, staticRenderFns } from "./post-featured.vue?vue&type=template&id=7b2286e2&scoped=true&"
var script = {}
import style0 from "./post-featured.vue?vue&type=style&index=0&id=7b2286e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b2286e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VContainer,VFlex})
