const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '60239eaa9e3d9d8ed979cbe4',
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    metadatas: {
      name: 'Écoute & vous',
      colorPrimary: '#ff6f61',
      colorAccent: '#69605d',
      supportEmailAddress: 'contact@ecouteetvous.com',
    },
    analytics: {
      matomo: '7',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        view: {
          allowHeadingOnMobile: false,
        },
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '65cb3497f82f1c2d9391e661',
            i18n: {
              fr: {
                title: 'Les partenaires associatifs',
                description: 'Tout savoir sur nos partenaires associatifs : prévention, dépistage, accompagnement et soins de support',
              },
            },
            heading: {
              color: '#ff6f61',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/popup_mobile_partenaires.png')
                  : require('~/assets/images/popup_desktop_partenaires.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '6540e4b50ce29a5332f39c51',
            i18n: {
              fr: {
                title: 'Cancer du sein',
                description: 'Tout savoir sur le cancer du sein : dépistage, accompagnement, chirurgies, prothèses...',
              },
            },
            heading: {
              color: '#d21233',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/cancersein-mobile.png')
                  : require('~/assets/images/cancersein.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '623da01f8cb8dbe05141cc5d',
            i18n: {
              fr: {
                title: 'Chirurgie',
                description: 'Tout savoir sur ce qui se passe avant, pendant et après l’intervention',
              },
            },
            heading: {
              color: '#ff6f61',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/chirurgie-mobile.png')
                  : require('~/assets/images/chirurgie.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '623da0377d7c806236fe9576',
            i18n: {
              fr: {
                title: 'Radiothérapie',
                description: 'Tout savoir sur ce traitement du cancer fait par des rayons',
              },
            },
            heading: {
              color: '#d21233',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/radiothérapie-mobile.png')
                  : require('~/assets/images/radiothérapie.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '623da0408cb8dbdfb741cc5e',
            i18n: {
              fr: {
                title: 'Bien-être',
                description: 'Tout savoir sur les à côté du traitement pour prendre soin de soi',
              },
            },
            heading: {
              color: '#ff6f61',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/bien-être-mobile.png')
                  : require('~/assets/images/bien-être.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '623da02e7d7c80ac66fe9575',
            i18n: {
              fr: {
                title: 'Traitements généraux',
                description: 'Tout savoir sur les traitements médicamenteux du cancer',
              },
            },
            heading: {
              color: '#d21233',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/traitements-généraux-mobile.png')
                  : require('~/assets/images/traitements-généraux.png')
              },
            },
          },
          {
            type: 'tag',
            tagId: '623da04a8cb8db708a41cc5f',
            i18n: {
              fr: {
                title: 'Hygiène/mode de vie',
                description: 'Tous les conseils pour un mode de vie sain',
              },
            },
            heading: {
              color: '#ff6f61',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/hygiène-modedevie-mobile.png')
                  : require('~/assets/images/hygiène-modedevie.png')
              },
            },
          },
        ],
      },
    ],
  },

  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
