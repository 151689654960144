<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-2 mr-2 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="primary" dark class="pa-2">
        <v-flex xs12>
          <v-card-text
            class="w-block__body2 text-alternate"
            :class="{
              'pa-2': $voicer.isMobile === false,
              'pa-1': $voicer.isMobile === true,
              'subtitle-2': $voicer.isMobile,
              'subtitle-2': $voicer.isMobile === false,
            }"
          >
            Ecoute & Vous, le podcast d'information sur les cancers, leurs
            traitements et tous les "à coté" des protocoles de soins
          </v-card-text>
          <div>
            <v-btn
              icon
              color="white"
              @click="
                handleOpen(
                  'https://www.facebook.com/Ecoute-Vous-103395135670434'
                )
              "
            >
              <v-icon color="#d21233">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              @click="
                handleOpen('https://www.instagram.com/ecoute_et_vous/?hl=fr')
              "
            >
              <v-icon color="#d21233">mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              @click="
                handleOpen('https://www.linkedin.com/company/ecouteetvous/')
              "
            >
              <v-icon color="#d21233">mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    handleOpen(url) {
      this.$spoke.eventManager.emit('click_link', {
        to: url,
        from_block: 'pre-heading',
      })
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-card {
  border-radius: 8px;
  cursor: pointer;
}
.v-btn {
  text-align: center;
}
</style>
